<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row justify="center">
        <v-col xl="6" lg="8" md="10" sm="11" cols="12">
          <v-card elevation="2">
            <v-card-text v-if="user" class="text-center">
              <v-row
                ><v-col cols="12" class="text_field_col"
                  ><h2 data-cy="text_myAccount" style="color: #0090a4">
                    My Account
                  </h2>
                </v-col></v-row
              >
              <v-card>
                <v-card-text>
                  <v-row
                    ><v-tabs class="table_tabs" v-model="selected_tab">
                      <v-tab data-cy="btn_myAccount_userSettings"
                        >General</v-tab
                      >
                      <v-tab>Address</v-tab>
                      <v-tab>Social Media</v-tab>
                      <v-tab data-cy="btn_myAccount_password">Password</v-tab>
                      <!-- <v-tab>Notifications</v-tab> -->
                    </v-tabs></v-row
                  >
                  <v-row class="mt-1" v-if="selected_tab == 0">
                    <v-col cols="6">
                      <v-row justify="center" class="mt-2 mb-2">
                        <v-avatar
                          size="150"
                          color="oc-grey"
                          class="image-border elevation-4"
                        >
                          <v-img
                            :src="picture + '?dummy=' + dummy_nums"
                            @click="return_url"
                          >
                            <template v-slot:placeholder>
                              <!-- <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey darken-5"
                                ></v-progress-circular>
                              </v-row> -->
                              <v-avatar
                                v-if="loading_image"
                                size="150"
                                color="primary"
                                class="white--text text-h3 elevation-4"
                              >
                                {{ userInitial }}
                              </v-avatar>
                              <v-progress-circular
                                v-else
                                style="
                                  height: 40px;
                                  width: 40px;
                                  top: calc(50% - 20px);
                                "
                                indeterminate
                                size="40"
                                color="primary"
                              ></v-progress-circular>
                            </template>
                          </v-img>
                        </v-avatar>
                        <!-- <v-avatar
                          v-else
                          size="150"
                          color="primary"
                          class="white--text text-h3 elevation-4"
                        >
                          {{ userInitial }}
                        </v-avatar> -->

                        <v-dialog v-model="add_image_dialog" width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              class="ml-n12"
                              color="grey"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              id="btn_add_image"
                            >
                              <v-icon>mdi-camera</v-icon>
                            </v-btn>
                          </template>
                          <v-card class="text-center">
                            <v-card-text class="mt-4 pb-2 px-4">
                              <v-row>
                                <v-toolbar elevation="0"
                                  ><h2 style="color: #0090a4">Add Image</h2>

                                  <v-spacer></v-spacer>
                                  <v-btn icon @click="add_image_dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn></v-toolbar
                                ></v-row
                              >
                              <v-file-input
                                label="Upload image here"
                                outlined
                                prepend-icon="mdi-camera"
                                v-model="image"
                              ></v-file-input>
                            </v-card-text>
                            <v-card-actions class="pt-0 pb-4">
                              <div style="width: 100%">
                                <div style="float: right">
                                  <v-btn
                                    class="mx-1"
                                    data-cy="btn_image_delete"
                                  >
                                    Delete
                                  </v-btn>
                                  <v-btn
                                    class="ml-2"
                                    :disabled="!image"
                                    color="primary"
                                    st
                                    @click="save_image"
                                    data-cy="btn_image_save"
                                  >
                                    Save
                                  </v-btn>
                                </div>
                              </div>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>

                      <span class="ml-2 mb-6">{{ user.email }}</span>
                    </v-col>
                    <v-col cols="6">
                      <v-row justify="center" class="mt-2 mb-2">
                        <v-avatar
                          size="150"
                          color="oc-grey"
                          class="image-border elevation-4"
                        >
                          <v-img
                            :src="company_image + '?dummy=' + dummy_nums_logo"
                            @click="return_url"
                          >
                            <template v-slot:placeholder>
                              <v-avatar
                                v-if="!loading_company_image"
                                size="150"
                                color="primary"
                                class="white--text elevation-4"
                              >
                                Company Logo
                              </v-avatar>

                              <v-progress-circular
                                v-else
                                style="
                                  height: 40px;
                                  width: 40px;
                                  top: calc(50% - 20px);
                                "
                                indeterminate
                                size="40"
                                color="primary"
                              ></v-progress-circular>
                            </template>
                          </v-img>
                        </v-avatar>

                        <v-dialog v-model="add_logo_dialog" width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              class="ml-n12"
                              color="grey"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-camera</v-icon>
                            </v-btn>
                          </template>
                          <v-card class="text-center">
                            <v-card-text class="mt-4 pb-2 px-4">
                              <v-row>
                                <v-toolbar elevation="0"
                                  ><h2 style="color: #0090a4">Add Image</h2>

                                  <v-spacer></v-spacer>
                                  <v-btn icon @click="add_logo_dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn></v-toolbar
                                ></v-row
                              >
                              <v-file-input
                                label="Upload image here"
                                outlined
                                prepend-icon="mdi-camera"
                                v-model="company_logo_image"
                              ></v-file-input>
                            </v-card-text>
                            <v-card-actions class="pt-0 pb-4">
                              <div style="width: 100%">
                                <div style="float: right">
                                  <v-btn
                                    class="mx-1"
                                    data-cy="btn_image_delete"
                                  >
                                    Delete
                                  </v-btn>
                                  <v-btn
                                    class="ml-2"
                                    :disabled="!company_logo_image"
                                    color="primary"
                                    @click="save_company_image"
                                    data-cy="btn_image_save"
                                  >
                                    Save
                                  </v-btn>
                                </div>
                              </div>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>

                      <span class="ml-2 mb-6"
                        >{{ customer.company_name }}
                      </span>
                    </v-col>
                    <v-form class="" v-model="isFormValid0">
                      <v-col cols="12" class="">
                        <v-row class="mb-n5"
                          ><v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="First Name"
                              v-model="customer.first_name"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Family Name*"
                              v-model="customer.last_name"
                              outlined
                            >
                            </v-text-field>
                          </v-col> </v-row
                        ><v-divider></v-divider
                      ></v-col>
                      <v-col cols="12" class="">
                        <v-row class="mb-n5"
                          ><v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <masked-field-phone
                              v-model="customer.phone_cell"
                              label="Cell Phone"
                              thick="true"
                            ></masked-field-phone>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <masked-field-phone
                              v-model="customer.phone_home"
                              label="Home Phone"
                              thick="true"
                            ></masked-field-phone>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider
                      ></v-col>
                      <v-col cols="12" class="">
                        <v-row class="mb-n5"
                          ><v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-select
                              label="Preferred Unit System"
                              v-model="customer.unit_system"
                              outlined
                              :items="[{text: 'Imperial', value: 'IMPERIAL'}, {text: 'Metric', value: 'METRIC'}]"
                            ></v-select>
                          </v-col> </v-row
                        ><v-divider></v-divider
                      ></v-col>
                      <v-col cols="12" class="">
                        <v-row class="mb-n5"
                          ><v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Company Name"
                              v-model="customer.company_name"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Occupation"
                              v-model="customer.company_occupation"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col
                            xl="6"
                            lg="6"
                            md="6"
                            sm="12"
                            cols="12"
                            class="text_field_col"
                          >
                            <v-text-field
                              label="Company Website"
                              v-model="customer.company_website"
                              outlined
                            ></v-text-field>
                          </v-col> </v-row
                      ></v-col>
                    </v-form>
                  </v-row>
                  <v-row class="mt-1" v-else-if="selected_tab == 1">
                    <v-col cols="12">
                      <h4 style="text-align: left" class="mb-6">
                        Mailing Address
                      </h4>
                      <v-row class="mb-n5">
                        <v-col cols="12" class="text_field_col">
                          <v-text-field
                            label="Address 1"
                            v-model="mailing_address.line_1"
                            outlined
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="text_field_col">
                          <v-text-field
                            label="Address 2"
                            v-model="mailing_address.line_2"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          xl="4"
                          lg="4"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="City"
                            v-model="mailing_address.city"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          xl="2"
                          lg="2"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="Province"
                            v-model="mailing_address.province"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          xl="4"
                          lg="4"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="Country*"
                            v-model="mailing_address.country"
                            outlined
                          >
                          </v-text-field>
                        </v-col>

                        <v-col
                          xl="2"
                          lg="2"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="Postal Code"
                            v-model="mailing_address.postal_code"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider> </v-divider>
                    </v-col>

                    <v-col cols="12">
                      <h4 style="text-align: left">Physical Address</h4>
                      <v-row
                        v-if="!same_address && physical_address"
                        class="mb-n"
                      >
                        <v-col cols="12" class="text_field_col pt-0">
                          <v-checkbox
                            label="Same Address"
                            v-model="same_address"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" class="text_field_col">
                          <v-text-field
                            label="Address 1"
                            v-model="physical_address.line_1"
                            outlined
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="text_field_col">
                          <v-text-field
                            label="Address 2"
                            v-model="physical_address.line_2"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          xl="4"
                          lg="4"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="City"
                            v-model="physical_address.city"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          xl="2"
                          lg="2"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="Province"
                            v-model="physical_address.province"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          xl="4"
                          lg="4"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="Country*"
                            v-model="physical_address.country"
                            outlined
                          >
                          </v-text-field>
                        </v-col>

                        <v-col
                          xl="2"
                          lg="2"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            label="Postal Code"
                            v-model="physical_address.postal_code"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-else class="mb-n5">
                        <v-col cols="12" class="text_field_col pt-0">
                          <v-checkbox
                            label="Same Address"
                            v-model="same_address"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1 pt-3" v-else-if="selected_tab == 2">
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Facebook"
                        v-model="customer.social_facebook"
                        outlined
                        prepend-inner-icon="mdi-facebook"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Instagram"
                        v-model="customer.social_instagram"
                        outlined
                        prepend-inner-icon="mdi-instagram"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Linkedin"
                        v-model="customer.social_linkedin"
                        outlined
                        prepend-inner-icon="mdi-linkedin"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="X"
                        v-model="customer.social_x"
                        outlined
                        prepend-inner-icon="mdi-x"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="YouTube"
                        v-model="customer.social_youtube"
                        outlined
                        prepend-inner-icon="mdi-youtube"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6" class="text_field_col">
                      <v-text-field
                        label="Other"
                        v-model="customer.social_other"
                        outlined
                        prepend-inner-icon="mdi-account-box-outline"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-n4" v-else-if="selected_tab == 3">
                    <v-container>
                      <v-form class="mt-5 mx-4" v-model="isFormValid">
                        <v-text-field
                          class="mx-10"
                          v-model="currentPassword"
                          data-testid="current-password"
                          label="Current Password"
                          :rules="[validateNotEmpty]"
                          outlined
                          clearable
                          :append-icon="
                            currentPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="
                            () =>
                              (currentPasswordVisible = !currentPasswordVisible)
                          "
                          :type="currentPasswordVisible ? 'text' : 'password'"
                          data-cy="field_currentPassword"
                        ></v-text-field>
                        <v-divider class="pt-5 mt-n2"></v-divider>
                        <v-text-field
                          class="mx-10"
                          v-model="newPassword"
                          data-testid="new-password"
                          label="New Password"
                          outlined
                          clearable
                          :append-icon="
                            newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="
                            () => (newPasswordVisible = !newPasswordVisible)
                          "
                          :type="newPasswordVisible ? 'text' : 'password'"
                          :rules="[validatePassword]"
                          data-cy="field_newPassword"
                        ></v-text-field>

                        <v-text-field
                          class="mx-10"
                          v-model="confirmPassword"
                          data-testid="confirm-password"
                          label="Confirm Password"
                          outlined
                          clearable
                          :append-icon="
                            newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'
                          "
                          @click:append="
                            () => (newPasswordVisible = !newPasswordVisible)
                          "
                          :type="newPasswordVisible ? 'text' : 'password'"
                          :rules="[
                            validateSamePassword(
                              this.newPassword,
                              this.confirmPassword
                            ),
                          ]"
                          data-cy="field_confirmPassword"
                        ></v-text-field>
                      </v-form>
                    </v-container>
                  </v-row>
                  <v-row class="mt-1 pt-3" v-else-if="selected_tab == 4">
                    <v-col>
                      <v-data-table
                        loading-text="Loading... Please wait"
                        :headers="headers_notifications"
                        :items="notification_preference"
                        :items-per-page="3"
                        class="elevation-0"
                        hide-default-footer
                      >
                        <template v-slot:[`item.title`]="{ item }">
                          <span>
                            {{
                              item.title.charAt(0).toUpperCase() +
                              item.title.slice(1)
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.email`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.email_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template v-slot:[`item.sms`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.sms_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template v-slot:[`item.call`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.call_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                      </v-data-table>
                    </v-col>

                    <v-col cols="12">
                      <v-data-table
                        loading-text="Loading... Please wait"
                        :headers="headers_camera_notifications"
                        :items="cameras"
                        :items-per-page="100"
                        class="elevation-0"
                        hide-default-footer
                      >
                        <template
                          v-slot:[`item.calving_notifications_enabled`]="{
                            item,
                          }"
                        >
                          <span style="width: 110px; display: inline-block"
                            ><v-select
                              v-model="item.calving_notifications_enabled"
                              hide-details
                              :items="[true, false]"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template
                          v-slot:[`item.estrus_notifications_enabled`]="{
                            item,
                          }"
                        >
                          <span style="width: 110px; display: inline-block"
                            ><v-select
                              v-model="item.estrus_notifications_enabled"
                              hide-details
                              :items="[true, false]"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template
                          v-slot:[`item.predator_notifications_enabled`]="{
                            item,
                          }"
                        >
                          <span style="width: 110px; display: inline-block"
                            ><v-select
                              v-model="item.predator_notifications_enabled"
                              hide-details
                              :items="[true, false]"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col cols="12" style="text-align: left" class="px-7">
                      <span v-if="customer.phone_cell"
                        >SMS notifications will be sent to
                        <span
                          @click="selected_tab = 0"
                          style="cursor: pointer"
                          class="primary--text"
                          >{{ customer.phone_cell }}</span
                        >
                        and email notifications will be sent to
                        <span
                          @click="selected_tab = 0"
                          style="cursor: pointer"
                          class="primary--text"
                          >{{ user.email }}</span
                        ></span
                      >
                      <span v-else
                        >You can set up your cellphone number
                        <span
                          @click="selected_tab = 0"
                          style="cursor: pointer"
                          class="primary--text"
                          >here</span
                        >
                        to receive SMS notifications.</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-col cols="12" class="pt-0 pb-0 pr-0">
                <v-row>
                  <v-col v-if="selected_tab != 3" class="text-right">
                    <v-btn
                      @click="save()"
                      class="save_btn ml-4"
                      color="primary"
                      :disabled="!made_changes || !isFormValid0"
                      data-cy="btn_save"
                      >Save</v-btn
                    ></v-col
                  ><v-col v-if="selected_tab == 3" class="text-right">
                    <v-btn
                      color="primary"
                      @click="changePassword"
                      :disabled="!isFormValid"
                      data-testid="change-password"
                      data-cy="btn_changePassword"
                    >
                      Change Password
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import axios from "axios";

import {
  validateSamePassword,
  validatePassword,
  validateNotEmpty,
  validateDelete,
} from "@/utilities/ValidationRules";
//import { isEqual } from "lodash";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { eventBus } from "../main.js";
import {
  initializeS3Client,
  initializeIAMClient,
  initializeCognitoIdentityClient,
} from "@/utilities/SessionUtils";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { ChangePasswordCommand } from "@aws-sdk/client-iam";
import MaskedFieldPhone from "../components/masks/MaskedFieldPhone.vue";

export default {
  name: "UserSettings",
  components: {
    navbar,
    sidenav,
    MaskedFieldPhone,
  },
  props: ["setting"],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        spec_char: (v) =>
          /^(?=.*[!@#$%^&_*]).{8,}$/.test(v) ||
          "Your password must contain at least one special character.",
        cap_let: (v) =>
          /^(?=.*[A-Z]).{8,}$/.test(v) ||
          "Your password must contain at least one capital letter.",
        number: (v) =>
          /^(?=.*[0-9]).{8,}$/.test(v) ||
          "Your password must contain at least one number.",
      },
      query_client: null,
      identity_id: null,
      customer: false,
      physical_address: {},
      mailing_address: {},
      selected_tab: 0,
      stepperCount: 1,
      email_pr: "",
      verificationCode: "",
      currentPasswordVisible: false,
      newPasswordVisible: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isFormValid: false,
      isFormValid0: false,
      success_snackbar: false,

      successful_update: false,
      error: null,

      add_image_dialog: false,
      add_logo_dialog: false,
      image: null,
      company_logo_image: null,
      company_image: false,
      dummy_nums: "9999999",
      dummy_nums_logo: "9999999",

      loading_image: true,
      loading_company_image: true,

      same_address: true,

      notification_preference: [
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "alert",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "warning",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "info",
        },
      ],
      notification_freq_items: [
        { value: "NEVER", text: "Never" },
        { value: "FIVE_MINUTES", text: "5 min" },
        { value: "FIFTEEN_MINUTES", text: "15 min" },
        { value: "THIRTY_MINUTES", text: "30 min" },
        { value: "ONE_HOUR", text: "1 hrs" },
        { value: "TWO_HOURS", text: "2 hrs" },
        { value: "FOUR_HOURS", text: "4 hrs" },
        { value: "EIGHT_HOURS", text: "8 hrs" },
        { value: "TWELVE_HOURS", text: "12 hrs" },
        { value: "ONE_DAY", text: "1 day" },
      ],
      cameras: [],
    };
  },
  computed: {
    cameras_equal() {
      var changed_cams = [[], []];
      if (!this.cameras.length || !this.cameras_initial.length) {
        return false;
      }
      for (const i in this.cameras_initial) {
        let check_diff = this.object_equal(
          this.cameras_initial[i],
          this.cameras[i]
        );
        if (check_diff) {
          changed_cams[0].push(this.cameras_initial[i].id);
          changed_cams[1].push(check_diff);
        }
      }
      if (changed_cams[0].length) {
        return changed_cams;
      }
      return false;
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    notification_preference_to_object() {
      var notification_preference_object = {};
      for (const i in this.notification_preference) {
        notification_preference_object[
          this.notification_preference[i].title + "_call_frequency"
        ] = this.notification_preference[i].call_frequency;
        notification_preference_object[
          this.notification_preference[i].title + "_email_frequency"
        ] = this.notification_preference[i].email_frequency;
        notification_preference_object[
          this.notification_preference[i].title + "_sms_frequency"
        ] = this.notification_preference[i].sms_frequency;
      }
      return notification_preference_object;
    },
    made_changes() {
      return (
        this.object_equal(this.customer_initial, this.customer) ||
        this.object_equal(this.mailing_address_initial, this.mailing_address) ||
        this.object_equal(
          this.physical_address_initial,
          this.physical_address
        ) ||
        this.object_equal(
          this.notification_preference_initial,
          this.notification_preference_to_object
        ) ||
        this.cameras_equal
      );
    },
    userInitial() {
      let f_name = this.user.given_name;
      let l_name = this.user.family_name;
      if (f_name === undefined || l_name === undefined) {
        return " ";
      }

      return f_name[0].toUpperCase() + l_name[0].toUpperCase();
    },

    user() {
      return this.$store.getters.getUser.attributes;
    },
    picture() {
      return (
        process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE +
        this.$store.getters.getUser.username +
        "/profile/" +
        this.$store.getters.getUser.username +
        "_300x300.jpg"
      );
    },
    customer_initial() {
      return this.$store.getters.getUserDDB;
    },
    physical_address_initial() {
      return this.$store.getters.getPhysicalAddress;
    },
    mailing_address_initial() {
      return this.$store.getters.getMailingAddress;
    },
    notification_preference_initial() {
      return this.$store.getters.getNotificationPreference;
    },
    headers_notifications() {
      return [
        {
          text: "Notification",
          value: "title",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "SMS",
          value: "sms",
        },
        // {
        //   text: "Call",
        //   value: "call",
        // },
      ];
    },
    headers_camera_notifications() {
      return [
        {
          text: "Camera",
          value: "name",
        },
        {
          text: "Calving",
          value: "calving_notifications_enabled",
          width: "60px",
        },
        {
          text: "Estrus",
          value: "estrus_notifications_enabled",
          width: "60px",
        },
        {
          text: "Predator",
          value: "predator_notifications_enabled",
          width: "60px",
        },
      ];
    },
    address_equal() {
      return this.address_equal_test(
        this.mailing_address,
        this.physical_address
      );
    },
    cameras_initial() {
      return this.$store.getters.getCameraObjects;
    },
  },
  methods: {
    validateSamePassword,
    validatePassword,
    validateNotEmpty,
    validateDelete,

    initialize_s3_client() {
      return initializeS3Client().then((client) => {
        this.query_client = client;
      });
    },

    address_equal_test(obj1, obj2) {
      if (!obj1 && !obj2) {
        return true;
      } else if (!obj1) {
        return false;
      } else if (!obj2) {
        return false;
      }
      for (const i in obj1) {
        if (obj1[i] != obj2[i] && i != "type") {
          return false;
        }
      }
      return true;
    },

    object_equal(original, updated) {
      var diff_object = {};
      for (const i in original) {
        if (original[i] != updated[i]) {
          diff_object[i] = updated[i];
        }
      }
      if (JSON.stringify(diff_object) === "{}") {
        return false;
      }
      return diff_object;
    },
    return_url() {
      this.dummy_nums = String(Math.floor(Math.random() * 9999999));
      this.loading_image = false;
    },
    return_image_url() {
      this.dummy_nums_logo = String(Math.floor(Math.random() * 9999999));
      this.loading_company_image = false;
    },
    changePassword() {
      initializeIAMClient().then((client) => {
        var iam_client = client;
        let input = {
          // ChangePasswordRequest
          OldPassword: this.currentPassword, // required
          NewPassword: this.newPassword, // required
        };
        var command = new ChangePasswordCommand(input);
        iam_client
          .send(command)
          .then(() => {
            eventBus.$emit("add_button_alert", {
              msg: `Successfully updated the password`,
              err: false,
            });
          })
          .catch((err) => {
            eventBus.$emit("add_button_alert", {
              msg: `Error updating the password`,
              err: true,
            });
            return err;
          });
      });
    },
    validate_email(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    async execute_query(type) {
      if (type == "customer") {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_customer_by_pk,
            variables: {
              pk_columns: { id: this.customer.id },
              _set: this.object_equal(this.customer_initial, this.customer),
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        return response.data;
      } else if (type == "mailing_address") {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_address_by_pk,
            variables: {
              pk_columns: { customer_id: this.customer.id, type: "MAILING" },
              _set: this.object_equal(
                this.mailing_address_initial,
                this.mailing_address
              ),
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        return response.data;
      } else if (type == "physical_address") {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_address_by_pk,
            variables: {
              pk_columns: { customer_id: this.customer.id, type: "PHYSICAL" },
              _set: this.object_equal(
                this.physical_address_initial,
                this.physical_address
              ),
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        return response.data;
      } else if (type == "notification_preference") {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_notification_preference_by_pk,
            variables: {
              pk_columns: { customer_id: this.customer.id },
              _set: this.object_equal(
                this.notification_preference_initial,
                this.notification_preference_to_object
              ),
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        return response.data;
      } else if (type == "cameras") {
        for (const c in this.cameras_equal[0]) {
          const response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_camera_by_pk,
              variables: {
                pk_columns: { id: this.cameras_equal[0][c] },
                _set: this.cameras_equal[1][c],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
        }

        return "success";
      }
    },

    async save() {
      let all_queries = [];
      if (this.cameras_equal) {
        all_queries.push(this.execute_query("cameras"));
      }
      if (this.object_equal(this.customer_initial, this.customer)) {
        all_queries.push(this.execute_query("customer"));
      }
      if (
        this.object_equal(this.mailing_address_initial, this.mailing_address)
      ) {
        all_queries.push(this.execute_query("mailing_address"));
      }
      if (
        this.object_equal(this.physical_address_initial, this.physical_address)
      ) {
        all_queries.push(this.execute_query("physical_address"));
      }
      if (
        this.object_equal(
          this.notification_preference_initial,
          this.notification_preference_to_object
        )
      ) {
        all_queries.push(this.execute_query("notification_preference"));
      }
      const results = await Promise.allSettled(all_queries);

      const hasError = results.some((result) => result.status === "rejected");

      if (hasError) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating customer`,
          err: true,
        });
        this.$store.dispatch("DDB_GET_CAMERAS");
      } else {
        this.$store.dispatch("DDB_GET_CUSTOMER", false);
        this.$store.dispatch("DDB_GET_CAMERAS");
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated customer`,
          err: false,
        });
      }
    },
    async save_user() {
      let user = await Auth.currentAuthenticatedUser();
      // console.log(this.user)
      try {
        await Auth.updateUserAttributes(user, this.user);

        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated the account`,
          err: false,
        });
      } catch (err) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating the account`,
          err: true,
        });
      }
    },
    async save_image() {
      var albumPhotosKey =
        this.identity_id +
        "/" +
        this.$store.getters.getUser.username +
        "/profile/";
      var photoKey =
        albumPhotosKey + this.$store.getters.getUser.username + ".jpg";
      // Use S3 ManagedUpload class as it supports multipart uploads
      var upload = new PutObjectCommand({
        Bucket: process.env.VUE_APP_UPLOAD_BUCKET,
        Key: photoKey,
        Body: this.image,
      });

      this.upload_img = false;
      // var self = this
      this.add_image_dialog = false;

      var _this = this;
      this.loading_image = true;
      this.query_client.send(upload).then(() => {
        _this.error = "Successfully updated account";

        _this.success_snackbar = true;

        setTimeout(() => {
          eventBus.$emit("update_navbar_img");
          _this.return_url();
        }, 4000);
      });
    },
    async save_company_image() {
      var albumPhotosKey =
        this.identity_id +
        "/" +
        this.$store.getters.getUser.username +
        "/company/images/";
      var photoKey =
        albumPhotosKey + this.$store.getters.getUser.username + "_logo.jpg";
      // Use S3 ManagedUpload class as it supports multipart uploads
      var upload = new PutObjectCommand({
        Bucket: process.env.VUE_APP_UPLOAD_BUCKET,
        Key: photoKey,
        Body: this.company_logo_image,
      });

      this.upload_img = false;
      // var self = this
      this.add_logo_dialog = false;

      var _this = this;
      this.loading_company_image = true;
      this.query_client.send(upload).then(() => {
        _this.error = "Successfully uploaded company logo";

        _this.success_snackbar = true;

        setTimeout(() => {
          eventBus.$emit("update_navbar_img");
          _this.return_image_url();
        }, 4000);
      });
    },
  },
  watch: {
    customer_id() {
      this.$store.dispatch("DDB_GET_CAMERAS");
    },
    cameras_initial() {
      this.cameras = JSON.parse(
        JSON.stringify(this.$store.getters.getCameraObjects)
      );
    },
    customer_initial() {
      if (this.customer_initial) {
        this.customer = JSON.parse(
          JSON.stringify(this.$store.getters.getUserDDB)
        );
      }
    },
    physical_address_initial() {
      if (this.physical_address_initial) {
        this.physical_address = JSON.parse(
          JSON.stringify(this.$store.getters.getPhysicalAddress)
        );
      }
    },
    mailing_address_initial() {
      if (this.mailing_address_initial) {
        this.mailing_address = JSON.parse(
          JSON.stringify(this.$store.getters.getMailingAddress)
        );
      }
    },
    notification_preference_initial() {
      if (this.notification_preference_initial) {
        var temp_notification_preference = [
          {
            call_frequency: "NEVER",
            email_frequency: "NEVER",
            sms_frequency: "NEVER",
            title: "alert",
          },
          {
            call_frequency: "NEVER",
            email_frequency: "NEVER",
            sms_frequency: "NEVER",
            title: "warning",
          },
          {
            call_frequency: "NEVER",
            email_frequency: "NEVER",
            sms_frequency: "NEVER",
            title: "info",
          },
        ];
        let level_index = { alert: 0, warning: 1, info: 2 };
        for (const i in this.notification_preference_initial) {
          temp_notification_preference[level_index[i.split("_")[0]]][
            i.split("_")[1] + "_frequency"
          ] = this.notification_preference_initial[i];
        }
        this.notification_preference = temp_notification_preference;
      }
    },
    address_equal() {
      if (
        !this.address_equal &&
        this.physical_address &&
        this.mailing_address
      ) {
        this.same_address = false;
      }
    },
    notification_preferenc: {
      handler: function () {
        this.notification_address_equal_test();
      },
      deep: true,
    },
  },

  async mounted() {
    if (this.customer_id) {
      this.$store.dispatch("DDB_GET_CAMERAS");
    }
    this.identity_id = await initializeCognitoIdentityClient();

    if (this.cameras_initial.length) {
      this.cameras = JSON.parse(
        JSON.stringify(this.$store.getters.getCameraObjects)
      );
    }

    if (this.customer_initial) {
      this.customer = JSON.parse(
        JSON.stringify(this.$store.getters.getUserDDB)
      );
    }
    if (this.mailing_address_initial) {
      this.mailing_address = JSON.parse(
        JSON.stringify(this.$store.getters.getMailingAddress)
      );
    }
    if (this.physical_address_initial) {
      this.physical_address = JSON.parse(
        JSON.stringify(this.$store.getters.getPhysicalAddress)
      );
    }

    if (this.notification_preference_initial) {
      var temp_notification_preference = [
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "alert",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "warning",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "info",
        },
      ];
      let level_index = { alert: 0, warning: 1, info: 2 };
      for (const i in this.notification_preference_initial) {
        temp_notification_preference[level_index[i.split("_")[0]]][
          i.split("_")[1] + "_frequency"
        ] = this.notification_preference_initial[i];
      }
      this.notification_preference = temp_notification_preference;
    }

    this.initialize_s3_client();
    this.return_url();
    this.return_image_url();
    var img = new Image();
    let logo_path =
      process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE +
      this.user.sub +
      "/company/images/" +
      this.user.sub +
      "_logo_300x300.jpg";
    img.src = logo_path;
    let _this = this;
    img.onload = function () {
      _this.company_image = logo_path;
    };
  },
};
</script>

<style lang="css">
.non_placed_hardware {
  cursor: initial;
}

.map_item_content_false {
  cursor: pointer !important;
}
.map_item_content_true {
  color: gray;
}
.map_side_bar .v-list-item {
  cursor: move;
}

.map_side_bar {
  overflow-y: scroll;
}
.settings_card_title {
  background-color: #ff9900;
}
.text_field_col {
  text-align: left;
}
.text_field_title {
  font-weight: bold;
}
.setting_tab {
  font-weight: bold;
  font-size: 14px;
}
.val_error {
  color: red;
}
</style>
