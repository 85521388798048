<template>
    <div>
        <v-text-field      
        @focus="$emit('focus');"
        @blur="$emit('blur')" 
        ref="input"
        type="tel"
        :placeholder="input_mask"
        :value="input_value" 
        @input.native="onInput"
        @input="submitValue"
        outlined
        :dense="!thick"
        :label="label"
        :class="thick?'':'text-validated pl-3 pr-3 pt-1'"
        :rules="[validatePhoneNumber]"
        ></v-text-field >
    </div>
</template>
<script>
import { validatePhoneNumber } from "@/utilities/ValidationRules";
export default {
    props: ["value", "label", "thick"],
    data(){
        return {
            input_value: "",
            focused: false,
            hover:false,
            input_mask: "+1 (___) ___-____",
            indexes: [],
        }
    },
    created(){
        this.input_value = this.applyMask(this.value, true);
    },
    watch: {
        value(){
            var value = this.applyMask(this.value, false);
            if(this.input_value!=value){
                this.input_value = value;
            }
        },
    },
    methods: {
      validatePhoneNumber,
        clear(){
            this.input_value = null;
        },
        submitValue(v){
            this.input_value = this.applyMask(v, true);
            if(this.checkFullyMasked(this.input_value)){
                // console.log('locked');
                this.$refs.input.$el.getElementsByTagName('input')[0].maxLength = this.input_mask.length;
            }else{
                this.$refs.input.$el.getElementsByTagName('input')[0].maxLength = this.input_mask.length+1;
            }
            if(this.input_value==""){
                this.$emit('input', "");
            }else{
                this.$emit('input', this.unmaskedValue(this.input_value));
            }
        },
        checkFullyMasked(v){
            return (v && v.length == this.input_mask.length && v.replace(/[^_]/gm, '').length <= 0);
        },
        isModifyingDistrictCode(){
            if(!this.$refs.input){
                return false;
            }
            var selectionStart = this.$refs.input.$el.getElementsByTagName('input')[0].selectionStart;
            return this.input_value.startsWith("+1 (") && selectionStart == 0;
        },
        getSelectedCharacterLength(){
            if(!this.$refs.input){
                return 0;
            }
            var selectionStart = this.$refs.input.$el.getElementsByTagName('input')[0].selectionStart;
            var value = this.$refs.input.$el.getElementsByTagName('input')[0].value;
            value = value.substring(0, selectionStart).replace(/[^0-9+]/gm, '');
            value = this.removeDistrictCode(value);
            return value.length;
        },
        applyMask(value, moving_caret){
            if(!value){
                return null;
            }
            var maxLength = this.input_mask.replace(/[^_]/gm, '').length;
            value = value.replace(/[^0-9+]/gm, '');
            value = this.removeDistrictCode(value);
            value = value.substring(0, Math.min(value.length, maxLength));
            var result = ""
            var cursor = 0;
            var selectionLength = this.getSelectedCharacterLength();
            for(let i=0; i<this.input_mask.length; i++){
                if(value.length>0 && selectionLength>0){
                    cursor++;
                }
                if(this.input_mask.charAt(i)== '_' && value.length>0){
                    result += value.charAt(0);
                    value = value.substring(1, value.length);
                    selectionLength-=1;
                }else{
                    result += this.input_mask.charAt(i);
                }
            }
            if(result == this.input_mask){
                result = "";
                cursor = 0;
            }

            if(moving_caret){
                this.$nextTick(()=>{
                    this.$refs.input.$el.getElementsByTagName('input')[0].setSelectionRange(cursor,cursor);
                });
            }
            return result;
            // if (!value) {
            //         return undefined;
            //     }
            //     value = value.replace(/[^0-9+]/gm, "");
            //     value = this.removeDistrictCode(value);
            //     // console.log("value3: "+value);
            //     var result = this.input_mask;
            //     var cursor =
            //         this.$refs.input.$el.getElementsByTagName("input")[0]
            //         .selectionStart;
            //     if (value.length == 1) {
            //         cursor = 5;
            //     }
            //     // console.log(selectionLength);
            //     for (let i = 0; i < value.length; i++) {
            //         result = result.replace("_", value.charAt(i));
            //     }
            //     result = result.split("_")[0];
            //     if (result[cursor] == "-") {
            //         cursor++;
            //     } else if (result[cursor] == ")") {
            //         cursor += 2;
            //     }
            //     this.$nextTick(() => {
            //         this.$refs.input.$el
            //         .getElementsByTagName("input")[0]
            //         .setSelectionRange(cursor, cursor);
            //     });
            //     return result;
        },
        onInput(event){
            var maxLength = this.input_mask.length;
            event.target.value = event.target.value.substring(0, Math.min(event.target.value.length, maxLength));
            event.target.value = this.applyMask(this.unmaskedValue(event.target.value), false);
            if(event.inputType == "insertFromPaste"){
                this.input_value = event.target.value;
                this.$emit('input', this.unmaskedValue(this.input_value));
            }
            this.$forceUpdate();
        },
        unmaskedValue(v){
            if(v== this.input_mask || v=="" || v==null){
                return null;
            }
            var value = `+${v.replace(/[^0-9]/gm, '')}`
            if(!value.startsWith("+1") || value =="+1"){
                value = value.replace("+", "+1");
            }
            return value;
        },
        removeDistrictCode(str){
            if(!str){
                return ""
            }
            if(str.includes("+1")){
                str = str.replace("+1", "");
            }
            return str;
        },
    }
}
</script>